<template>
  <div class="marquee">
    <div v-for="(list, index) in formattedLists" :key="index" class="marquee__content-group">
      <div v-for="(item, idx) in list" :key="idx" class="marquee__content">
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MarqueeComponent',
	props:['lists'],
  computed: {
    formattedLists() {
      return [this.lists, this.lists]; // Always duplicate the lists
    },
  },
};
</script>

<style scoped>
.marquee {
  color:#ff65d4;
  display: flex;
  animation: marquee 10s linear infinite;
  white-space: nowrap;
}

.marquee__content-group {
  display: flex;
}

.marquee__content {
  padding: 0 20px;
  margin-right: 150px;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
